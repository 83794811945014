import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  endOfWeek,
  format,
  isSameDay,
  isSameWeek,
  isToday,
  startOfWeek,
  subDays,
  differenceInDays
} from 'date-fns';

export type WeekSelected = {
  startDate: Date;
  endDate: Date;
};

@Component({
  selector: 'app-date-picker',
  template: `
    <div class="h-full">
      <div (appClickOutside)="showDatepicker = false">
        <label for="datepicker" class="font-bold mb-1 text-gray-700 block"
          >Select Date</label
        >    
        <div class="relative flex items-center gap-2">
          <button
            type="button"
            class="
              transition
              ease-in-out
              duration-100
              inline-flex
              cursor-pointer
              hover:bg-gray-200
              p-1
              rounded-full
            "
            (click)="setDateValue(this.currentValue.getDate() - 7)" 
          >
            <svg
              class="h-6 w-6 text-gray-500 inline-flex"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          <input
            type="text"
            readonly
            [value]="dateLabel"
            (click)="showDatepicker = !showDatepicker"
            (keydown.escape)="showDatepicker = false"
            class="
              w-full
              pl-4
              pr-10
              py-3
              leading-none
              rounded-lg
              drop-shadow-md
              focus:outline-none focus:shadow-outline
              text-gray-600
              font-medium
              cursor-pointer
            "
            placeholder="Select date"
          />

          <div
            (click)="showDatepicker = !showDatepicker"
            class="absolute top-0 right-10 px-3 py-2 cursor-pointer"
          >
            <svg
              class="h-6 w-6 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>

          <!-- <div {{numberOfDays.length"></div>
            <div {{32 - new Date(year, month, 32).getDate()"></div>
            <div {{new Date(year, month).getDay()"></div> -->
          <button
            type="button"
            class="
              transition
              ease-in-out
              duration-100
              inline-flex
              cursor-pointer
              p-1
              rounded-full
            "
            [ngClass]="this.isLatestWeek() ? 'cursor-not-allowed' : 'hover:bg-gray-200'"
            [disabled]="this.isLatestWeek()"
            (click)="setDateValue(this.currentValue.getDate() + 7)" 
          >
            <svg
              class="h-6 w-6 inline-flex"
              [ngClass]="this.isLatestWeek()? 'text-gray-300' : 'text-gray-500'"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        <div
          class="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0 z-10"
          style="width: 17rem"
          [hidden]="!showDatepicker"
          (keydown.away)="showDatepicker = false"
        >
          <div class="flex justify-between items-center mb-2">
            <div>
              <span class="text-lg font-bold text-gray-800">{{
                MONTH_NAMES[month]
              }}</span>
              <span class="ml-1 text-lg text-gray-600 font-normal">{{
                year
              }}</span>
            </div>
            <div>
              <button
                type="button"
                class="
                transition
                ease-in-out
                duration-100
                inline-flex
                cursor-pointer
                hover:bg-gray-200
                p-1
                rounded-full
              "
                (click)="month = month - 1"
                (click)="getNoOfDays()"
                (click)="changeYear(-1)"
              >
                <svg
                  class="h-6 w-6 text-gray-500 inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                type="button"
                class="
                transition
                ease-in-out
                duration-100
                inline-flex
                cursor-pointer
                hover:bg-gray-200
                p-1
                rounded-full
              "
                (click)="month = month + 1"
                (click)="getNoOfDays()"
                (click)="changeYear(1)"
              >
                <svg
                  class="h-6 w-6 text-gray-500 inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div class="flex flex-wrap mb-3 -mx-1">
            @for (day of DAYS; track day; let index = $index) {
              <div style="width: 14.26%" class="px-1">
                <div class="text-gray-800 font-medium text-center text-xs">
                  {{ day }}
                </div>
              </div>
            }
          </div>

          <div class="flex flex-wrap -mx-1">
            @for (blankday of blankdays; track blankday) {
              <div
                style="width: 14.28%"
                class="text-center border-none p-1 border-transparent text-sm"
              ></div>
            }
            @for (
              date of numberOfDays;
              track trackByIdentity(dateIndex, date);
              let dateIndex = $index
            ) {
              <div style="width: 14.28%" class="px-1 mb-1">
                <div
                  (click)="setDateValue(date)"
                  class="
                  cursor-pointer
                  text-center text-sm
                  rounded-full
                  leading-loose
                  transition
                  ease-in-out
                  duration-100
                "
                  [ngClass]="{
                    '!bg-primary text-gray-900': isToday(date),
                    'bg-gray-300': isSelected(date),
                    'text-gray-700 hover:bg-gray-800 hover:text-white':
                      !isToday(date)
                  }"
                >
                  {{ date }}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  `,
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() ngModel = new Date();
  @Input() mode: 'day' | 'week' = 'day';
  @Input() labelFormat = 'dd/MM/yy';

  @Output() dateRange = new EventEmitter<WeekSelected>();
  @Output() ngModelChange = new EventEmitter<Date>();

  readonly MONTH_NAMES: readonly string[] = new Array(12)
    .fill(0)
    .map((_, i) => format(new Date(0, i), 'MMMM'));
  readonly DAYS: readonly string[] = new Array(7)
    .fill(0)
    .map((_, i) => format(new Date(0, 0, i + 1), 'EE'));

  showDatepicker = false;
  currentValue = this.ngModel;

  month = this.ngModel.getMonth();
  year = this.ngModel.getFullYear();
  today = new Date();

  numberOfDays = [] as number[];
  blankdays = [] as number[];

  get dateLabel() {
    if (this.mode === 'week') {
      return (
        format(this.weekStart, this.labelFormat) +
        ' - ' +
        format(this.weekEnd, this.labelFormat)
      );
    } else {
      return format(this.currentValue, this.labelFormat);
    }
  }

  ngOnInit(): void {
    this.getNoOfDays();
  }

  get weekStart() {
    return startOfWeek(this.currentValue);
  }

  get weekEnd() {
    return endOfWeek(this.currentValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('ngModel' in changes) {
      this.currentValue = changes['ngModel'].currentValue;
      this.month = this.currentValue.getMonth();
      this.year = this.currentValue.getFullYear();
      this.getNoOfDays();
    }
  }

  isToday(date: any): boolean {
    return isToday(new Date(this.year, this.month, date));
  }

  isLatestWeek(): boolean {
    return differenceInDays(this.today, this.currentValue) < 7
  }

  setDateValue(date: number) {
    this.currentValue = new Date(this.year, this.month, date);

    this.showDatepicker = false;

    const startDate = this.weekStart;
    const endDate = subDays(this.weekEnd, 2);

    if (this.mode === 'week') {
      this.dateRange.emit({ startDate, endDate });
    }
    this.ngModelChange.emit(this.currentValue);
  }

  isSelected(date: number) {
    if (this.mode === 'week') {
      return isSameWeek(
        this.currentValue,
        new Date(this.year, this.month, date),
      );
    } else {
      return isSameDay(
        this.currentValue,
        new Date(this.year, this.month, date),
      );
    }
  }

  getNoOfDays() {
    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
    // find where to start calendar day of week
    const dayOfWeek = new Date(this.year, this.month).getDay() || 7;
    let blankdaysArray: number[] = [];
    for (var i = 1; i < dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysArray: number[] = [];
    for (var i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    this.blankdays = blankdaysArray;
    this.numberOfDays = daysArray;
  }

  changeYear(value: number) {
    if (value > 0) {
      if (this.month > 11) {
        this.month = 0;
        this.year++;
      }
    } else {
      if (this.month < 0) {
        this.month = 11;
        this.year--;
      }
    }
  }

  trackByIdentity = (index: number, item: any) => item;
}
